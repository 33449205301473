import React, { lazy } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  ScrollRestoration,
} from "react-router-dom";

const PaydayLoan = lazy(() => import("./pages/PaydayLoan"));
const Home = lazy(() => import("./pages/Home"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Blog = lazy(() => import("./pages/Blog"));
const HowToGetLoanForStudying = lazy(
  () => import("./pages/BlogPages/HowToGetLoanForStudying")
);
const HowToGetLoanLearnLanguage = lazy(
  () => import("./pages/BlogPages/HowToGetLoanLearnLanguage")
);
const CareerGrowthInIt = lazy(
  () => import("./pages/BlogPages/CareerGrowthInIt")
);
const BlogFiveLifeHacksFromCreditExpert = lazy(
  () => import("./pages/BlogPages/BlogFiveLifeHacksFromCreditExpert")
);
const ReceiveInUkraine = lazy(
  () => import("./pages/BlogPages/ReceiveInUkraine")
);
const HowApplyForAutomaticLoanFromMfo = lazy(
  () => import("./pages/BlogPages/HowApplyForAutomaticLoanFromMfo")
);
const StartCareerInItToday = lazy(
  () => import("./pages/BlogPages/StartCareerInItToday")
);
const CarLoan = lazy(() => import("./pages/CarLoan"));
const StudyLoan = lazy(() => import("./pages/StudyLoan"));
const FamilyLoan = lazy(() => import("./pages/FamilyLoan"));
const Stock = lazy(() => import("./pages/Stock"));
const FaqPage = lazy(() => import("./pages/FaqPage"));
const LoyaltyProgram = lazy(() => import("./pages/LoyaltyProgram"));
const NeedMoney = lazy(() => import("./pages/StockPages/NeedMoney"));
const TestStudentLoan = lazy(() => import("./pages/TestStudentLoan"));
const MoneyBeforeSalary = lazy(
  () => import("./pages/StockPages/MoneyBeforeSalary")
);
const MoneyAutumn = lazy(() => import("./pages/StockPages/MoneyAutumn"));
const SimpleSteps = lazy(() => import("./pages/StockPages/SimpleSteps"));
const RomanceCost = lazy(() => import("./pages/StockPages/RomanceCost"));
const DocumentsAreRequired = lazy(
  () => import("./pages/StockPages/DocumentsAreRequired")
);
const MoneyAutumnContinues = lazy(
  () => import("./pages/StockPages/MoneyAutumnContinues")
);
const TeacherSchool = lazy(() => import("./pages/StockPages/TeacherSchool"));
const InterestRate = lazy(() => import("./pages/StockPages/InterestRate"));
const BlackFriday = lazy(() => import("./pages/StockPages/BlackFriday"));
const ReviewsPage = lazy(() => import("./pages/ReviewsPage"));
const HappyHolidayDiscount2024 = lazy(
  () => import("./pages/StockPages/HappyHolidayDiscount2024")
);
const ValentinesDay2024 = lazy(
  () => import("./pages/StockPages/ValentinesDay2024")
);
const AssessFinancialCapabilities = lazy(
  () => import("./pages/BlogPages/AssessFinancialCapabilities")
);
const ProlongationGuide = lazy(() => import("./pages/ProlongationGuide"));

const AppLayout = () => (
  <>
    <ScrollRestoration />
    <Outlet />
  </>
);

const routers = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <PaydayLoan />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blog/loan-for-studying",
        element: <HowToGetLoanForStudying />,
      },
      {
        path: "/blog/loan-learn-language",
        element: <HowToGetLoanLearnLanguage />,
      },
      {
        path: "/blog/career-growth-in-it",
        element: <CareerGrowthInIt />,
      },
      {
        path: "/blog/five-life-hacks-from-credit-expert",
        element: <BlogFiveLifeHacksFromCreditExpert />,
      },
      {
        path: "/blog/receive-247-in-ukraine",
        element: <ReceiveInUkraine />,
      },
      {
        path: "/blog/how-apply-for-automatic-loan-from-mfo",
        element: <HowApplyForAutomaticLoanFromMfo />,
      },
      {
        path: "/blog/start-career-in-it-today",
        element: <StartCareerInItToday />,
      },
      {
        path: "/car-loan",
        element: <CarLoan />,
      },
      {
        path: "/student-loan",
        element: <StudyLoan />,
      },
      {
        path: "/family-loan",
        element: <FamilyLoan />,
      },
      {
        path: "/promoactionnews",
        element: <Stock />,
      },
      {
        path: "/promoactionnews/need-money",
        element: <NeedMoney />,
      },
      {
        path: "/promoactionnews/money-before-salary",
        element: <MoneyBeforeSalary />,
      },
      {
        path: "/promoactionnews/money-autumn",
        element: <MoneyAutumn />,
      },
      {
        path: "/promoactionnews/4-simple-steps",
        element: <SimpleSteps />,
      },
      {
        path: "/promoactionnews/romance-cost",
        element: <RomanceCost />,
      },
      {
        path: "/promoactionnews/documents-are-required",
        element: <DocumentsAreRequired />,
      },
      {
        path: "/promoactionnews/money-autumn-continues",
        element: <MoneyAutumnContinues />,
      },
      {
        path: "/promoactionnews/teacher-school",
        element: <TeacherSchool />,
      },
      {
        path: "/promoactionnews/interest-rate",
        element: <InterestRate />,
      },
      {
        path: "/promoactionnews/black-friday",
        element: <BlackFriday />,
      },
      {
        path: "/promoactionnews/happy-holiday-24-12-2024",
        element: <HappyHolidayDiscount2024 />,
      },
      {
        path: "/promoactionnews/valentines-day-2025",
        element: <ValentinesDay2024 />,
      },
      {
        path: "/uk/pitannya-ta-vidpovidi",
        element: <FaqPage />,
      },
      {
        path: "/loyalty-program",
        element: <LoyaltyProgram />,
      },
      {
        path: "/testing-student-loan",
        element: <TestStudentLoan />,
      },
      {
        path: "/reviews",
        element: <ReviewsPage />,
      },
      {
        path: "/blog/assess-financial-capabilities",
        element: <AssessFinancialCapabilities />,
      },
      {
        path: "/prolongation-guide",
        element: <ProlongationGuide />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
]);

export default routers;
