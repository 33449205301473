import React, { useEffect, lazy, useState, Suspense } from "react";
import Favicon from "react-favicon";
import { RouterProvider } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import moment from "moment";

import faicon_img from "./assets/favicon/logo.png";
import TawkToChat from "./components/TawkToChat/TawkToChat";
import { LS_KEY_AGREE_WITH_COOKIES } from "./const/lsKeys";
import routers from "./routers";

import "./App.scss";
import "assets/fonts/Montserrat/font-face.css";

const Cookies = lazy(() => import("./components/Cookies/Cookies"));

function App() {
  const [showCookies, setShowCookies] = useState(false);

  useEffect(() => {
    const timeWrite = localStorage.getItem("timeWrite");

    if (timeWrite) {
      const parseTimeWrite = moment(timeWrite)
        .add(1, "day")
        .format("DD.MM.YYYY HH:mm:ss");
      const now = moment(new Date()).format("DD.MM.YYYY HH:mm:ss");

      if (parseTimeWrite < now) {
        localStorage.removeItem("utm_campaign");
        localStorage.removeItem("utm_source");
        localStorage.removeItem("utm_medium");
        localStorage.removeItem("aff_sub");
        localStorage.removeItem("timeWrite");
      }
    }

    const agreeWithCookies = localStorage.getItem(LS_KEY_AGREE_WITH_COOKIES);
    if (agreeWithCookies === null || agreeWithCookies === "false") {
      setShowCookies(true);
    }
  }, []);

  return (
    <>
      <Favicon url={faicon_img} />
      <Suspense>
        <RouterProvider router={routers} />
      </Suspense>
      <TawkToChat />
      <ScrollToTop smooth />
      {showCookies && <Cookies />}
    </>
  );
}

export default App;
