import React from "react";

import { TAWK_ACCOUNT_KEY, TAWK_ID } from "../../const/api";
import { useInteraction } from "lib/hooks/useInteraction";

const TawkToChat = () => {
  useInteraction(() => {
    if (!window.Tawk_API) {
      var Tawk_API = window.Tawk_API || {},
        Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = `https://embed.tawk.to/${TAWK_ACCOUNT_KEY}/${TAWK_ID}`;
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  });

  return null;
};

export default TawkToChat;
